import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Seo from "../components/Seo"

const page404 = () => {
  return (
    <Layout>
      <Seo seo="" />
      <Container>
        <div className="max-w-[45rem] mx-auto pb-16 md:pb-32">
          <header className="max-w-4xl pt-8 md:pt-16 pb-4">
            <h1 className="text-[2.625rem] md:text[3.375rem] lg:text-6xl font-light">
              404 - Page not found
            </h1>
          </header>
        </div>
      </Container>
    </Layout>
  )
}

export default page404
